<template>
  <!-- Main Content -->
  <div id="content" class="mb-5">
    <!-- Begin Page Content -->
    <div id="carne-digital" class="container-fluid">
      <div class="d-sm-flex align-items-center justify-content-between my-4 pt-2 mx-md-4">
        <h1 class="h3 mb-0">Carnê Digital</h1>
      </div>
      <div class="alert alert-warning" role="alert" v-if="exibeErrorMessage">
        {{ errorMessage }}
      </div>
      <div v-if="carregando" class="text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        Carregando contratos ... 
      </div>
      <div v-if="semContratos">
        Você não possui contratos ativos.
      </div> 
      <div class="row mx-md-2 mx-xl-3">
        <div class="item col-xl-6 col-xxl-4"  v-for="(contrato, index) in contratos" v-bind:key="index">
          <CarneDigitalItem :contrato="contrato"></CarneDigitalItem>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- End of Main Content -->
</template>


<script>

import CarneDigitalItem from "../components/CarneDigitalItem";
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import AuthServiceContext from "../services/AuthService";

export default {
  name:'carnedigital',
  components:{
    CarneDigitalItem
  },
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      contratos:[],
      errorMessage:'',
      primeiraConsulta: false
    }
  },
  computed: {
    exibeErrorMessage: function() {
      return this.errorMessage != '';
    },
    carregando: function() {
      return !this.primeiraConsulta;
    },
    semContratos: function() {
      return this.errorMessage == '' && (this.contratos && this.contratos.length == 0) && this.primeiraConsulta;
    }
  },
  mounted() {

    this.$nextTick(() => {

      let config = this.authService.config();
      let contratosApi = new ApiPortalAutoAtendimento.ContratoApi();
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.basePath = config.endpoints.autoatendimento;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()
      let userData = this.authService.userData();

      let empresa = userData.Empresa; 
      let cpfcliente = userData.CpfCnpj; 
      // let cpfcliente = '57752419087';
      let situacao = "A"; 
      
      if (cpfcliente == "" || this.authService.isFastAccessCompleto()){
        cpfcliente = localStorage.cpfCnpj;
      }

      contratosApi.consultaContratosPorCliente(empresa, cpfcliente, situacao, (error, data, response) => {
        this.primeiraConsulta = true;
        if (error) {
          this.errorMessage = 'Erro ao obter contratos.';
        } else {
          this.contratos = data;
        }
          this.contratos = data;
          this.errorMessage = '';
      });    
    });
  }
}
</script>
